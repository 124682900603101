
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { debounce } from '@/util/helper';
import { Category } from '@/interfaces/models/Category';
import { StockManagementType } from '@/enums/StockManagementType';

interface Selection {
  search?: string;
  type?: string;
  status?: string;
  category?: string;
}

enum StatusFormFilter {
  ALL = 'all',
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
}

@Component
export default class ArticleManagerFilter extends Vue {
  @Prop({ type: Array, required: true }) public categories!: Category[];

  public search: string = '';
  public type: string = '';
  public status: string = '';
  public category: string = '';

  get selection() {
    const selection: Selection = {};
    if (this.search !== '') {
      selection.search = this.search;
    }
    if (this.type !== '') {
      selection.type = this.type;
    }
    if (this.status !== '') {
      selection.status = this.status;
    }
    if (this.category !== '') {
      selection.category = this.category;
    }

    return selection;
  }

  get statusArticle() {
    return [
      {
        value: StatusFormFilter.ALL,
        text: this.$t('articleManager.filter.allStatus'),
      },
      {
        value: StatusFormFilter.ACTIVE,
        text: this.$t('articleManager.filter.active'),
      },
      {
        value: StatusFormFilter.NOT_ACTIVE,
        text: this.$t('articleManager.filter.notActive'),
      },
    ];
  }

  get typeArticle() {
    return [
      { text: this.$t('articleManager.filter.allStatus'), value: StockManagementType.ALL },
      { text: this.$t('articleManager.filter.article'), value: StockManagementType.ARTICLE },
      { text: this.$t('articleManager.filter.options'), value: StockManagementType.OPTION },
    ];
  }

  @Watch('selection')
  public onChange() {
    const self = this;
    debounce(() => {
      self.$emit('change', self.selection);
    }, 500);
  }
}
