export enum DisplayMode {
  DEFAULT = 'default',

  LAUNCHER = 'launcher',

  SMART_PICKER = 'smartPicker',

  DETAIL_OVERLAY = 'detailOverlay',

  GRID = 'grid',

  PAGED_GRID = 'paged_grid',

  CIRCLE = 'circle',

  TOP_SELLER = 'topSeller',

  DETAIL_DIALOG = 'detailDialog',

  MATRIX = 'matrix',

  BANNER = 'banner',

  DRINK_FLAVOR = 'drinkFlavor',

  TOGGLE_BUTTON = 'toggleButton',

  FLAVOR = 'flavor',

  RADIO = 'radio',

  CHECKBOX = 'checkbox',

  CODE = 'code',
}
