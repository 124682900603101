
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import { Permission } from '@/enums/Permission';
import { VExpansionPanels, VExpansionPanel, VSwitch } from 'vuetify/lib';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { ArticleCategory } from '@/interfaces/models/ArticleCategory';
import { DisplayMode } from '@/enums/DisplayMode';
import { DisplayIdentifier } from '@/enums/DisplayIdentifier';
import { StockManagementType } from '@/enums/StockManagementType';
import ArticleManagerFilter from '@/components/articleManager/ArticleManagerFilter.vue';
import ArticleManagerLoader from '@/components/articleManager/ArticleManagerLoader.vue';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import Filter from '@/interfaces/api/Filter';

const foodcard = namespace('foodcard');
const venue = namespace('venue');
const articleManager = namespace('articleManager');

interface GroupedArticle {
  parentName: string;
  connectedArticles: { de: string }[];
  parentDescription: string;
  article: Article;
  type: StockManagementType;
}

@Component({
  components: {
    VWrapper,
    VExpansionPanels,
    VExpansionPanel,
    ArticleManagerFilter,
    VSwitch,
    ArticleManagerLoader,
  },
})
export default class ArticleManager extends Vue {
  @venue.State('active') public venue!: Venue;
  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;
  @foodcard.Action('setOptionFilter') public setOptionFilter!: any;
  @foodcard.Action('fetchOptions') public getOptions!: any;
  @foodcard.State((state) => state.articlePagination.total) public total!: number;
  @foodcard.State('options') protected options!: OptionGroup[];
  @foodcard.State('categories') public categories!: Category[];

  @articleManager.State('filter') public filter!: Filter;
  @articleManager.Getter('filteredArticles') public filteredArticles!: GroupedArticle[];
  @articleManager.Getter('filteredOptionArticles') public filteredOptionArticles!: GroupedArticle[];
  @articleManager.Getter('articles') public articles!: GroupedArticle[];
  @articleManager.Getter('optionArticles') public optionArticles!: GroupedArticle[];

  @articleManager.Action('makeArticleVisible') public makeArticleVisible!: any;
  @articleManager.Action('hideArticle') public hideArticle!: any;
  @articleManager.Action('hideOptionArticle') public hideOptionArticle!: any;
  @articleManager.Action('makeArticleOptionVisible') public makeArticleOptionVisible!: any;
  @articleManager.Action('setGroupedArticle') protected setGroupedArticle!: any;
  @articleManager.Action('setFilter') public setArticleFilter!: any;
  @articleManager.Action('reset') public reset!: any;

  public $refs!: {};
  public activeArtice: GroupedArticle | null = null;
  public showArticleModal: boolean = false;

  public activePanel: number | null = null;

  public async mounted(): Promise<void> {
    if (!this.venue) return;
    await this.fetchData();
  }

  public beforeDestroy() {
    this.reset();
  }

  public async fetchData() {
    if (!this.venue?._id) return;
    try {
      this.$startLoading('articleManager');
      this.setOptionFilter({ venue: this.venue._id });
      if (this.$can(Permission.CATEGORY_VIEW)) {
        this.setCategoryFilter({ venue: this.venue._id, articles: true });
        await this.getCategories();
      }

      await this.getOptions();
      this.initData();
    } catch (e) {
      console.log(e);
    } finally {
      this.$stopLoading('articleManager');
    }
  }

  public initData() {
    const optionGroupsMap: Map<string | number, OptionGroup> = new Map(this.options.map((group) => [group._id, group]));

    const categories = this.categories.map((category: Category) => ({
      ...category,
      articles: category.articles?.map((article: any) => ({
        ...article,
        groups: article.groups?.map((group: any) => optionGroupsMap.get(group as string) || group),
        categoryName: category.name.de,
      })),
    }));

    const groupedStockManagementGroups: { article: GroupedArticle[]; option: GroupedArticle[] } =
      this.generateGroupedStockManagementGroups(categories);

    this.setGroupedArticle(groupedStockManagementGroups);
  }

  private generateGroupedStockManagementGroups(categories: any[]) {
    const items = this.generateItems(categories);

    return items.reduce((acc, group) => {
      if (!group) return acc;

      const { type } = group;
      acc[type] = acc[type] || [];
      acc[type].push(group);

      return acc;
    }, {} as Record<string, any[]>);
  }

  private generateItems(categories: ArticleCategory[]) {
    const ogFilter = (optionGroup: OptionGroup) =>
      !(
        (optionGroup.articles?.length === 1 && optionGroup.limit === 1 && optionGroup.requiredAmount === 1) ||
        optionGroup.displayMode === DisplayMode.DETAIL_OVERLAY ||
        optionGroup.displayIdentifiers?.includes(DisplayIdentifier.menu)
      );

    const allArticles = this.extractArticles(categories, ogFilter);

    return this.extractStockManagementGroups(categories, allArticles, ogFilter);
  }

  private extractArticles(categories: ArticleCategory[], ogFilter: (optionGroup: OptionGroup) => boolean) {
    return categories
      .flatMap(
        (category) =>
          category.articles?.map((article: any) => ({
            ...article,
            categoryId: category._id,
            groups: article.groups?.filter(ogFilter as any),
          })) || [],
      )
      .filter((article, index, self) => index === self.findIndex((a) => a._id === article._id));
  }

  private extractStockManagementGroups(
    categories: ArticleCategory[],
    allArticles: any[],
    ogFilter: (optionGroup: OptionGroup) => boolean,
  ) {
    const stockManagementGroups: any[] = [];

    stockManagementGroups.push(
      ...allArticles.map((article) => ({
        type: StockManagementType.ARTICLE,
        article,
        parentName: article.categoryName || '',
      })),
    );

    const optionGroups = categories
      .flatMap((category) => (category.articles ?? []).flatMap((article: any) => article.groups))
      .filter((group, index, self) => index === self.findIndex((g) => g._id === group._id))
      .filter(ogFilter);

    stockManagementGroups.push(...this.extractOptions(optionGroups, allArticles));

    return stockManagementGroups;
  }

  private extractOptions(optionGroups: OptionGroup[], allArticles: Article[]) {
    return optionGroups
      .flatMap(
        (og) =>
          og.articles?.map((article: any) => {
            const foundGroup: any = optionGroups.find((group) =>
              group.articles.find((a: any) => a._id === article._id),
            );

            const connectedArticles = foundGroup
              ? allArticles.filter((art) => art.groups.includes(foundGroup as any)).map((art) => art.name)
              : [];

            return {
              parentName: foundGroup?.name.de || '',
              connectedArticles,
              parentDescription: foundGroup?.description?.de || '',
              article,
              type: StockManagementType.OPTION,
            };
          }) || [],
      )
      .filter(Boolean);
  }

  public async onSearch(queries: any) {
    this.setArticleFilter(queries);
  }

  public toggleOptions(item: GroupedArticle) {
    this.activeArtice = item;
    this.showArticleModal = true;
  }

  public async toggleAllVisibility(value: boolean) {
    try {
      this.$startLoading('articleManager');
      const articles = this.articles.map((item: GroupedArticle) => item.article._id);
      if (value) {
        await this.makeArticleVisible({ venue: this.venue._id, articles });
      } else {
        await this.hideArticle({ venue: this.venue._id, articles });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.$stopLoading('articleManager');
    }
  }

  public async toggleAllOptionGroupVisibility(value: boolean, item: OptionGroup | string) {
    if (typeof item === 'string') return;

    const articles = item?.articles?.map((item: OptionArticle) => item._id);
    if (value) {
      await this.makeArticleOptionVisible({ venue: this.venue._id, articles });
    } else {
      await this.hideOptionArticle({ venue: this.venue._id, articles });
    }
  }

  public async toggleAllOptionArticleVisibility(value: boolean) {
    try {
      this.$startLoading('articleManager');
      const articles = this.optionArticles.map((item: GroupedArticle) => item.article._id);
      if (value) {
        await this.makeArticleOptionVisible({ venue: this.venue._id, articles });
      } else {
        await this.hideOptionArticle({ venue: this.venue._id, articles });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.$stopLoading('articleManager');
    }
  }

  public async toggleOptionArticleVisibility(value: Boolean, item: Partial<GroupedArticle>) {
    if (!item?.article?._id) return;

    this.$startLoading('articleManager');
    if (value) {
      this.makeArticleOptionVisible({ venue: this.venue._id, articles: [item.article._id] });
    } else {
      this.hideOptionArticle({ venue: this.venue._id, articles: [item.article._id] });
    }
    this.$stopLoading('articleManager');
  }

  public async toggleVisibility(value: Boolean, item: Partial<GroupedArticle>) {
    if (!item?.article?._id) return;

    this.$startLoading('articleManager');
    if (value) {
      this.makeArticleVisible({ venue: this.venue._id, articles: [item.article._id] });
    } else {
      this.hideArticle({ venue: this.venue._id, articles: [item.article._id] });
    }
    this.$stopLoading('articleManager');
  }

  public canToggleArticleVisibility(val: boolean) {
    if (val) {
      return this.$can(Permission.ARTICLE_VISIBLE);
    }
    return this.$can(Permission.ARTICLE_HIDE);
  }

  public canToggleOptionArticleVisbility(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_VISIBLE);
    }
    return this.$can(Permission.OPTION_ARTICLE_HIDE);
  }

  @Watch('venue')
  public async onVenueChange() {
    if (!this.venue) return;
    this.reset();
    await this.fetchData();
  }
}
